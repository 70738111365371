import React, { useEffect, useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { FiSun, FiMoon } from "react-icons/fi";
import { useTheme } from "../context/themes/ThemeContext";

const AppBar: React.FC = () => {
  const { theme, toggleTheme } = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [activeSection, setActiveSection] = useState<string>("");
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const navigateToSection = (section: string) => {
    setActiveSection(section);
    navigate(`/?v=${section}`);
    document.getElementById(section)?.scrollIntoView({ behavior: "smooth" });
    setIsOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navItemClass = (section: string) => `
    px-3 py-2 text-lg font-medium transition duration-300 cursor-pointer
    ${
      activeSection === section
        ? "border-l-2 lg:border-l-0 lg:border-b-2 border-[#3a74ba] text-[#3a74ba]"
        : "text-gray-700  text-gray-700 dark:text-gray-300 hover:text-[#3a74ba] hover:border-l-2 hover:lg:border-l-0 hover:lg:border-b-2 hover:border-[#3a74ba] "
    }

  `;

  return (
    <header
      className={`sticky top-0 w-full z-50 transition-all duration-500 text-gray-700 dark:bg-gray-950  dark:text-gray-300 ease-in-out ${
        isScrolled
          ? "bg-transparent"
          : " bg-white shadow border-b-0 dark:bg-gray-950 text-gray-700 dark:text-gray-300 border-gray-400"
      }`}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-16">
          <div className="flex items-center space-x-4">
            <img
              className={`bg-cover p-2 ${
                theme === "dark"
                  ? "dark-classname h-14 object-cover"
                  : "light-classname h-14 object-cover"
              }`}
              src={theme === "dark" ? "/logow.png" : "/logo.png"}
              alt="Logo"
              loading="lazy"
            />
          </div>
          <nav className="hidden sm:flex space-x-6 justify-center flex-grow">
            <a
              target="blank"
              href="https://documenter.getpostman.com/view/18114527/2sA3QwbVPz"
              className="text-gray-700 dark:text-gray-300 hover:text-[#3a74ba] px-3 py-2 text-lg font-medium transition duration-300"
            >
              APIs
            </a>
            <div
              className={navItemClass("aboutus")}
              onClick={() => navigateToSection("aboutus")}
            >
              تواصل معنا
            </div>

            <div
              className={navItemClass("pricing")}
              onClick={() => navigateToSection("pricing")}
            >
              الأسعار
            </div>
            <div
              className={navItemClass("services")}
              onClick={() => navigateToSection("services")}
            >
              خدماتنا
            </div>
            <div
              className={navItemClass("home")}
              onClick={() => navigateToSection("home")}
            >
              الرئيسية
            </div>
          </nav>
          <div className="hidden sm:flex items-center space-x-4">
            <div className="flex justify-end gap-4">
              <div>
                <button
                  onClick={toggleTheme}
                  className="p-2 rounded-full focus:outline-none focus:ring-offset-2"
                  aria-label="Toggle Dark Mode"
                >
                  {theme === "dark" ? (
                    <FiSun size={24} />
                  ) : (
                    <FiMoon size={24} />
                  )}
                </button>
              </div>
              <div>
                {" "}
                <Link
                  to="/login"
                  className="flex items-center font-bold bg-[#2a66b1] mb-1 text-gray-100 hover:bg-[#1060aa]  px-6 py-2 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105"
                >
                  تسجيل الدخول
                </Link>
              </div>
            </div>
          </div>
          <div className="block lg:hidden mx-2">
            <button
              onClick={toggleMenu}
              className="inline-flex items-center justify-center p-2 mt-2 rounded-md bg-gray-50 dark:bg-gray-950 text-gray-700 dark:text-gray-300 ring-1 ring-offset-1 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2"
            >
              {isOpen ? (
                <FaTimes className="h-6 w-6" aria-hidden="true" />
              ) : (
                <FaBars className="h-6 w-6" aria-hidden="true" />
              )}
            </button>
          </div>
        </div>
      </div>

      {isOpen && (
        <div
          className=" sticky inset-0 bg-white dark:bg-gray-950 text-gray-700 dark:text-gray-300 z-40"
          onClick={toggleMenu}
        ></div>
      )}

      <div
        className={`fixed top-16 right-0 h-full w-full  bg-white dark:bg-gray-950 text-gray-700 dark:text-gray-300 shadow-md transform ${
          isOpen ? "-translate-x-0" : "translate-x-full"
        } transition-transform duration-300 ease-in-out lg:hidden z-50`}
      >
        <div className="flex items-center justify-between px-4 pb-2 border-t dark:border-gray-600 border-gray-300">
          <div>
            <button
              onClick={toggleTheme}
              className="px-6 pt-3 pt ml-3 rounded-full focus:outline-none focus:ring-offset-2"
              aria-label="Toggle Dark Mode"
            >
              {theme === "dark" ? (
                <FiSun className="text-gray-100" size={24} />
              ) : (
                <FiMoon size={24} />
              )}
            </button>
          </div>
        </div>

        <div className="px-8 pt-4 pb-3 space-y-1">
          <div
            className={navItemClass("home")}
            onClick={() => navigateToSection("home")}
          >
            الرئيسية
          </div>
          <div
            className={navItemClass("pricing")}
            onClick={() => navigateToSection("pricing")}
          >
            الأسعار
          </div>
          <div
            className={navItemClass("services")}
            onClick={() => navigateToSection("services")}
          >
            خدماتنا
          </div>
          <div
            className={navItemClass("aboutus")}
            onClick={() => navigateToSection("aboutus")}
          >
            تواصل معنا
          </div>
          <a
            target="blank"
            href="https://documenter.getpostman.com/view/18114527/2sA3QwbVPz"
            className="text-gray-700 dark:text-gray-300 block px-3 py-2 text-base font-medium hover:bg-gray-800 rounded-md transition duration-300"
          >
            APIs
          </a>
        </div>
        <div className="sm:flex absolute top-96 h-svh justify-center items-center mx-4">
          <Link
            to="/login"
            className="flex items-center text-gray-100 justify-centerfont-bold bg-[#2a66b1] mb-1 hover:bg-[#1060aa]  px-6 py-2 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105"
          >
            تسجيل الدخول
          </Link>
        </div>
      </div>
    </header>
  );
};

export default AppBar;
