import React from "react";
import { FaWhatsapp } from "react-icons/fa";

const WhatsAppButton: React.FC = () => {
  const whatsappNumber = "967780088180"; // Replace with your WhatsApp number
  const message = "مرحبا, كيف يمكننا مساعدتكم."; // Optional: Default message

  const whatsappLink = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(
    message
  )}`;

  return (
    <div className="fixed bottom-4 right-8 z-50 bg-green-400 p-1 rounded-full shadow-lg transition-transform duration-300 transform hover:scale-105 hover:shadow-2xl md:bottom-3 md:right-3">
      <a
        href={whatsappLink}
        target="_blank"
        rel="noopener noreferrer"
        className="block relative"
      >
        <FaWhatsapp className="text-gray-50 p-1" size={55}/>
        <span className="absolute -top-10 left-1/2 transform -translate-x-1/2 px-2 py-1 text-sm text-white bg-gray-800 rounded-lg whitespace-nowrap opacity-0 group-hover:opacity-100 transition-opacity duration-300">
          Contact us on WhatsApp
        </span>
      </a>
    </div>
  );
};

export default WhatsAppButton;
