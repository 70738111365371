import React, {
  createContext,
  useState,
  useEffect,
  ReactNode,
  useContext,
  useCallback,
} from "react";
import { AuthContext } from "../AuthContext";
import axios from "axios";

interface Schedule {
  id: string;
  user_id: number;
  device_id: string;
  msgId: string;
  from: string | null;
  to: string;
  type: string;
  message: string;
  send_type: string;
  status: string;
  created_at: string;
  updated_at: string;
}

interface MessageData {
  [x: string]: any;
  current_page: number;
  Messages: Schedule[];
}

interface MessageApiContextType {
  Messages: MessageData | null;
  loading: boolean;
  error: string | null;
  fetchMessages: () => Promise<void> | null;
  setLoadingS: React.Dispatch<React.SetStateAction<boolean>>;
}

interface MessageApiProviderProps {
  children: ReactNode;
}

export const MessageApiContext = createContext<
  MessageApiContextType | undefined
>(undefined);

const MessageApiProvider: React.FC<MessageApiProviderProps> = ({
  children,
}) => {
  const [Messages, setMessages] = useState<MessageData | null>(null);
  const [loading, setLoadingS] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { token } = useContext(AuthContext);

  const fetchMessages = useCallback(async () => {
    const url =
      "https://whats1.sky.sky4system.com/api/user/MesssageTransactions";

    const formData = new FormData();

    const headers: HeadersInit = {};

    if (token) {
      headers["auth-token"] = token;
    }

    try {
      const response = await axios.post(url, formData, {
        headers: {
          ...(token ? { "auth-token": token } : {}),
        },
        params: {
          token,
        },
      });
      if (!response) {
        throw new Error(`HTTP error! status: ${response}`);
      }

      const data = await response.data;

      // console.log(data.data[0]);

      if (data.status === true) {
        setMessages(data.data);
      } else {
        setError(data.msg || "Failed to fetch Messages");
      }
    } catch (err) {
      setError("An error occurred while fetching the Messages");
    } finally {
      setLoadingS(false);
    }
  }, [token]);

  useEffect(() => {
    fetchMessages();
  }, [fetchMessages]);

  return (
    <MessageApiContext.Provider
      value={{
        Messages,
        loading,
        error,
        setLoadingS,
        fetchMessages,
      }}
    >
      {children}
    </MessageApiContext.Provider>
  );
};

export default MessageApiProvider;

// const fetchScheduleById = useCallback(
//   async (id: string) => {
//     const url =
//       "https://whats1.sky.sky4system.com/api/user/GET-Schedule-byid";
//     const headers: HeadersInit = {};
//     const formData = new FormData();
//     formData.append("id", id);

//     if (token) {
//       headers["auth-token"] = token;
//     }

//     try {
//       const response = await axios.post(url, formData, {
//         headers: {
//           ...(token ? { "auth-token": token } : {}),
//         },
//         params: {
//           token,
//         },
//       });

//       if (!response) {
//         throw new Error(`HTTP error! status: ${response}`);
//       }

//       const data = await response.data;

//       if (data.status === true && data.data.length > 0) {
//         // console.log(data);
//         return data.data[0];
//       } else {
//         setError(data.msg || "Failed to fetch the schedule by ID");
//         return null;
//       }
//     } catch (err) {
//       setError("An error occurred while fetching the schedule by ID");
//       return null;
//     }
//   },
//   [token]
// );
