import React, { useCallback, useContext, useState } from "react";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import { MessageApiContext } from "../../../context/messages/MessageApiContext";

import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import DateFilter from "@inovua/reactdatagrid-community/DateFilter";
import DateFilterr from "./DateFilter";
import moment from "moment";
import { format } from "date-fns";
import NumberFilter from "@inovua/reactdatagrid-community/NumberFilter";

if (typeof window !== "undefined") {
  (window as any).moment = moment;
}

const DataGrid: React.FC = () => {
  const context = useContext(MessageApiContext);
  const [cellSelection, setCellSelection] = useState({ "2,name": true });
  const [enableFiltering, setEnableFiltering] = useState(true);

  const [filteredMessages, setFilteredMessages] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const statuses = [
    { id: "pendding", label: "قيد الانتظار" },
    { id: "completed", label: "مرسلة" },
    { id: "falid", label: "فاشلة" },
  ];

  const defaultFilterValue = [
    { name: "status", operator: "eq", type: "select", value: "pendding" },
    { name: "updated_at", operator: "eq", type: "date", value: "" },
    { name: "message", operator: "contains", type: "string", value: "" },
    { name: "from", operator: "gte", type: "number", value: 1 },
    { name: "to", operator: "gte", type: "number", value: 1 },

    // { name: "type", operator: "contains", type: "string", value: "" }
  ];

  const columns = [
    // { name: "from", header: "From", defaultFlex: 1 },
    {
      name: "from",
      header: "المرسل",
      defaultFlex: 1,
      type: "number",
      filterEditor: NumberFilter,
    },
    {
      name: "to",
      header: "المستلم",
      defaultFlex: 1,
      type: "number",
      filterEditor: NumberFilter,
    },
    // { name: "device_id", header: "Device ID", defaultFlex: 1 },
    {
      name: "message",
      header: "الرسالة",
      defaultFlex: 1,
      render: ({ value }: { value: string }) => {
        try {
          const parsedMessage = JSON.parse(value);

          // Check if the message contains an image
          if (parsedMessage.image) {
            return (
              <div>
                <img
                  src={parsedMessage.image.url}
                  alt="Message"
                  className="w-20 h-auto cursor-pointer"
                  onClick={() => {
                    setSelectedImage(parsedMessage.image.url);
                    setIsModalOpen(true);
                  }}
                />
                {parsedMessage.caption && <p>{parsedMessage.caption}</p>}
              </div>
            );
          }

          // Otherwise, render the text message
          if (parsedMessage.text) {
            return <p>{parsedMessage.text}</p>;
          }

          return <p>Unknown message format</p>;
        } catch (error) {
          return <p>Invalid message format</p>;
        }
      },
    },

    {
      name: "status",
      header: "الحالة",
      defaultFlex: 1,
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "All",
        dataSource: statuses,
      },
    },
    {
      name: "updated_at",
      header: "تاريخ الارسال",
      defaultFlex: 1,
      filterEditor: DateFilter,
      filterEditorProps: {
        dateFormat: "MM-DD-YYYY",
        placeholder: "Select date",
      },
      // render: ({ value }: { value: string }) => moment(value).format('MM-DD-YYYY')
      render: ({ value }: { value: string }) =>
        format(new Date(value), "MM-dd-yyyy"),
    },
  ];

  const gridStyle = { minHeight: 550 };

  const filterMessagesByDate = useCallback(
    (startDate: string, endDate: string) => {
      if (!context || !context.Messages) return;

      const filtered = context.Messages.filter((message: any) => {
        const messageDate = moment(message.updated_at);
        return messageDate.isBetween(moment(startDate), moment(endDate));
      });

      setFilteredMessages(filtered);
    },
    [context]
  );

  if (!context) {
    throw new Error(
      "MessageApiContext must be used within a MessageApiProvider"
    );
  }

  const { Messages, loading, error } = context;

  if (loading) {
    return <div className="max:w-6xl py-12 px-16">Loading schedules...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const dataSource = Array.isArray(Messages)
    ? Messages.map((message) => ({
        id: message.id,
        from: message.from,
        to: message.to,
        device_id: message.device_id,
        message: message.message,
        status: message.status,
        updated_at: message.updated_at,
      }))
    : [];

  return (
    <div className="mt-16 mx-5 overflow-y-auto">
      <DateFilterr onDateFilterChange={filterMessagesByDate} />

      <ReactDataGrid
        idProperty="id"
        style={gridStyle}
        columns={columns}
        enableFiltering={enableFiltering}
        pagination
        cellSelection={cellSelection}
        defaultLimit={10}
        rowHeight={150}
        showZebraRows={true}
        // onCellSelectionChange={setCellSelection}
        dataSource={filteredMessages.length ? filteredMessages : dataSource}
        defaultFilterValue={defaultFilterValue}
      />
      {isModalOpen && (
        <div className="fixed inset-0 py-4 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-4 rounded shadow-lg">
            <button
              className="mt-12 text-red-500"
              onClick={() => setIsModalOpen(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            {selectedImage && (
              <img
                src={selectedImage}
                alt="Preview"
                className="max-w-full max-h-screen"
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default DataGrid;
