// src/components/Content.tsx
import React from "react";
import CartList from "../../components/cart/CartList";
import UserMaster from "./devices/UserMaster";
import { Outlet } from 'react-router-dom';

const Content: React.FC = () => {
  return (
    <div className="hide-scrollbar ">
      <CartList />
      <UserMaster/>
      <Outlet />
    </div>
  );
};

export default Content;
