import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AuthProvider from "./context/AuthContext";
import Login from "./pages/auth/Login";
import NotFound from "./pages/NotFound";
import PrivateRoute from "./routes/PrivateRoute";
import DeviceMasterProvider from "./context/devices/DeviceMasterContext";
import UserMasterProvider from "./context/UserMasterContext";
import Layout from "./components/layouts/Layout";
import Contentt from "./pages/Content";
import SignUp from "./pages/auth/SignUp";
import CreateUserProvider from "./context/CreateUserContext";
import CreateDeviceProvider from "./context/devices/CreatDeviceContex";
import OpenDesviceDetials from "./pages/dashboard/devices/OpenDesviceDetials";
import TextComponent from "./pages/dashboard/messages/TextComponent";
import Content from "./pages/dashboard/Content";
import MediaComponent from "./pages/dashboard/messages/MediaComponent";
import EditProfile from "./pages/dashboard/profile/EditProfile";
import OrderPage from "./pages/dashboard/orders/OrderPage";
import DeviceOrderProvider from "./context/devices/DeviceOrederContext";
import PricingSection from "./pages/dashboard/orders/PricingSection";
import DevicePeriodContextProvider from "./context/devices/DevicePeriodContext";
import OrderSubscripPage from "./pages/dashboard/orders/OrderSubscripPage";
import EditPromotion from "./pages/dashboard/orders/EditPromotion";
import ProtectedRoute from "./routes/ProtectedRoute";
import PlanContextProvider from "./context/devices/PlanContext";
import UpdatePlan from "./pages/dashboard/orders/UpdatePlan";
// import ContactProvider from "./context/contactsgroups/ContactContext";
import DeviceUseDetails from "./pages/dashboard/devices/DeviceUseDetails";
import GroupsProvider from "./context/contactsgroups/GroupsContext";
import TextGroupComponent from "./pages/dashboard/messages/TextGroupComponent";
import MediaGroupComponent from "./pages/dashboard/messages/MediaGroupComponent";
import CompaignComponent from "./pages/dashboard/messages/campaign/CompaignComponent";
import ContactsProvider from "./context/contactsgroups/ContactContext";
import ScheduleProvider from "./context/messages/ScheduleContext";
import ComainMessagecomponent from "./pages/dashboard/messages/campaign";
import UpdateCompainSMS from "./pages/dashboard/messages/campaign/UpdateCompainSMS";
import GroupContactComponent from "./pages/dashboard/messages/groups";
import GroupProvider from "./context/contactsgroups/GroupContactContext";
// import CreateNewGroup from "./pages/dashboard/messages/groups/CreateNewGroup";
import CreateGroupCoding from "./pages/dashboard/messages/groups/CreateGroupCoding";
import CreateContactsId from "./pages/dashboard/messages/groups/CreateContactsId";
import RepetationProvider from "./context/devices/RepetatioContext";
import GroupAdnContacts from "./pages/dashboard/messages/groups/GroupAdnContacts";
import DataGrid from "./pages/dashboard/reports/DataGrid";
import MessageApiProvider from "./context/messages/MessageApiContext";

const App: React.FC = () => {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route
            path="/signup/*"
            element={
              <CreateUserProvider>
                <SignUp />
              </CreateUserProvider>
            }
          />
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Contentt />} />

          <Route element={<PrivateRoute element={<Layout />} />}>
            <Route
              path="/*"
              element={
                <UserMasterProvider>
                  <DeviceMasterProvider>
                    <CreateDeviceProvider>
                      <DeviceOrderProvider>
                        <DevicePeriodContextProvider>
                          <PlanContextProvider>
                            <GroupsProvider>
                              <ContactsProvider>
                                <RepetationProvider>
                                  <ScheduleProvider>
                                    <GroupProvider>
                                      <MessageApiProvider>
                                        <Routes>
                                          <Route
                                            path="/dashboard"
                                            element={<Content />}
                                          >
                                            <Route
                                              path="devices/:deviceId"
                                              element={<OpenDesviceDetials />}
                                            />
                                            <Route
                                              path=":deviceId"
                                              element={<DeviceUseDetails />}
                                            />
                                          </Route>

                                          <Route
                                            path="/sendmessages"
                                            element={<TextComponent />}
                                          />
                                          <Route
                                            path="/sendmefilessages"
                                            element={<MediaComponent />}
                                          />
                                          <Route
                                            path="/groupsm"
                                            element={<TextGroupComponent />}
                                          />
                                          <Route
                                            path="/groupsendf"
                                            element={<MediaGroupComponent />}
                                          />
                                          <Route
                                            path="/sendcampaign"
                                            element={<CompaignComponent />}
                                          />
                                          <Route
                                            path="/updatecampaign/:id"
                                            element={<UpdateCompainSMS />}
                                          />
                                          <Route
                                            path="/sendcampaignsms"
                                            element={<ComainMessagecomponent />}
                                          />
                                          {/* groups-contact */}
                                          <Route
                                            path="/groupcontact"
                                            element={<GroupContactComponent />}
                                          />
                                          <Route
                                            path="/groupcontact/:id"
                                            element={<CreateContactsId />}
                                          />
                                          <Route
                                            path="/creatnewgroup"
                                            element={<GroupAdnContacts />}
                                          />
                                          <Route
                                            path="/codegroup"
                                            element={<CreateGroupCoding />}
                                          />
                                          {/* groups-contact */}
                                          <Route
                                            path="/editprofile"
                                            element={<EditProfile />}
                                          />
                                          <Route
                                            path="/orders"
                                            element={<OrderPage />}
                                          />
                                          <Route
                                            path="/promotions"
                                            element={<PricingSection />}
                                          />
                                          <Route
                                            path="/updatepricing"
                                            element={
                                              <ProtectedRoute
                                                element={<UpdatePlan />}
                                              />
                                            }
                                          />
                                          <Route
                                            path="/updatepromotions"
                                            element={
                                              <ProtectedRoute
                                                element={<EditPromotion />}
                                              />
                                            }
                                          />
                                          <Route
                                            path="/reports"
                                            element={<DataGrid />}
                                          />
                                          <Route
                                            path="/promotions/:planId"
                                            element={<OrderSubscripPage />}
                                          />

                                          <Route
                                            path="/*"
                                            element={<NotFound />}
                                          />
                                        </Routes>
                                      </MessageApiProvider>
                                    </GroupProvider>
                                  </ScheduleProvider>
                                </RepetationProvider>
                              </ContactsProvider>
                            </GroupsProvider>
                          </PlanContextProvider>
                        </DevicePeriodContextProvider>
                      </DeviceOrderProvider>
                    </CreateDeviceProvider>
                  </DeviceMasterProvider>
                </UserMasterProvider>
              }
            />
            <Route path="/*" element={<NotFound />} />
          </Route>
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;
