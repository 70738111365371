import React, { useState } from "react";
import moment from "moment";
import { Value as DateTimePickerValue } from "react-datetime-picker/dist/cjs/shared/types";
import TimePickerComponent from "../messages/campaign/TimePickerComponent";

interface DateFilterProps {
  onDateFilterChange: (startDate: string, endDate: string) => void;
}

const DateFilter: React.FC<DateFilterProps> = ({ onDateFilterChange }) => {
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [customEndDate, setCustomEndDate] = useState<DateTimePickerValue>(
    new Date() // Initial state is set to the current date
  );
  const handleDateChange = (option: string) => {
    setSelectedOption(option);

    let startDate = "start";
    let endDate = "end";

    switch (option) {
      case "now":
        // startDate = moment().startOf("day").toISOString();
        // endDate = moment().endOf("day").toISOString();
        startDate = moment().subtract(1,"day").toISOString();
        endDate = moment().toISOString();
        break;
      case "last24":
        startDate = moment().subtract(24, "hours").toISOString();
        endDate = moment().toISOString();
        break;
      case "daily":
        startDate = moment().startOf("day").toISOString();
        endDate = moment().endOf("day").toISOString();
        break;
      case "weekly":
        startDate = moment().startOf("week").toISOString();
        endDate = moment().endOf("week").toISOString();
        break;
      case "monthly":
        startDate = moment().startOf("month").toISOString();
        endDate = moment().endOf("month").toISOString();
        break;
      case "lastMonth":
        startDate = moment()
          .subtract(1, "month")
          .startOf("month")
          .toISOString();
        endDate = moment().subtract(1, "month").endOf("month").toISOString();
        break;
      default:
        break;
    }

    if (option !== "custom") {
      onDateFilterChange(startDate, endDate);
    }
  };

  const handleDateChangeClick = (value: DateTimePickerValue) => {
    if (value instanceof Date) {
      setCustomEndDate(value); // Keep the date in its original format
    } else {
      setCustomEndDate(null); // Handle null or invalid values
    }
  };

  return (
    <div dir="rtl" className="my-2">
      <p className="flex-1 my-2">الرسائل المرسلة في الفترة</p>
      <select
        className="text-sm text-gray-900 border focus:outline-none border-gray-300 rounded-lg bg-gray-50 focus:ring-gray-300 focus:border-gray-300 p-1 dark:focus:border-gray-400"
        value={selectedOption}
        onChange={(e) => handleDateChange(e.target.value)}
      >
        <option value="">البحث بتاريخ الارسال</option>
        <option value="now">اليوم حتى الان</option>
        <option value="last24">أخر 24 ساعة</option>
        {/* <option value="daily">هذا الاليوم </option> */}
        <option value="weekly">أخر 7 ايام</option>
        <option value="lastMonth">قبل شهر</option>
        <option value="monthly">هذا الشهر حتى الان</option>
        {/* <option value="custom">تخصيص</option> */}
      </select>

      {selectedOption === "custom" && (
        <div className="flex-1">
          <TimePickerComponent
            initialValue={customEndDate}
            onChange={handleDateChangeClick}
          />
        </div>
      )}
    </div>
  );
};

export default DateFilter;
