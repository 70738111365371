import React from "react";
import { FaTimes } from "react-icons/fa";

interface NotificationProps {
  message: string;
  type: "success" | "error";
  onClose: () => void;
}

const Notification: React.FC<NotificationProps> = ({ message, type, onClose }) => {
  const bgColor = type === "success" ? "bg-green-600" : "bg-red-600";
  const textColor = type === "error" ? "text-gray-50" : "text-gray-50";

  return (
    <div
      className={`absolute top-16 right-8 flex gap-3 w-auto p-4 rounded-lg opacity-75 shadow-lg ${bgColor} ${textColor} flex `}
    >
      <p>{message}</p>
      <button onClick={onClose} className="ml-2 text-lg flex-1">
        <FaTimes />
      </button>
    </div>
  );
};

export default Notification;
